<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="이벤트" />
				</div>
			</div>
		</div>
		<div class="page-content event-check eventdibs">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title"><span class="prima">동행축제 이벤트에 참여하고</span> 푸짐한 선물 받으세요.</h3>
				</div>
			</div>
			<div class="page-tab-container">
				<div class="container tab-buttons">
					<router-link to="check" class="tab-button">출석체크</router-link>
					<router-link to="dibs" class="tab-button is-active">찜하기</router-link>
					<router-link to="sns" class="tab-button">구매후기</router-link>
				</div>
			</div>
			<div class="container">
				<div class="event-check-container">
					<div class="event-check-header">
						<div class="event-check-header-banner">
							<div class="banner-titlewrap">
								<h3 class="banner-title">찜하기 이벤트</h3>
								<span class="banner-text">갖고 싶은 동행제품을 찜 해주세요.</span>
								<span class="banner-text boldtxt">추첨을 통해 경품을 드립니다.</span>
							</div>
							<div class="banner-img">
								<img src="@/assets/img/event/banner_icon.jpg" alt="" />
							</div>
						</div>
						<div class="event-check-header-info">
							<ul class="event-check-header-info-list">
								<li v-for="(item, idx) in infoList" :key="idx">
									<span class="infolist-title">{{ item.title }}</span>
									<span class="infolist-text">{{ item.detail }}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-guide">
						<span class="event-check-guide-title">참여방법</span>
						<div class="event-check-guide-desc">
							<ul class="event-check-guide-desclist">
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img">
										<img src="@/assets/img/event/icon_img01.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 1</span>
										<span class="desclist-text">본인인증을 통해 로그인해 주세요.</span>
									</div>
								</li>
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img">
										<img src="@/assets/img/event/icon_img02.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 2</span>
										<span class="desclist-text">
											동행제품을 둘러보고 <span class="boldtxt">5가지 제품 한도 내에서 원하는 제품을 찜하세요.</span> 
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-products">
						<span class="event-check-products-title">경품소개</span>
						<ul class="event-check-products-list">
							<li class="event-check-products-list-li" v-for="(item, idx) in prdList" :key="idx">
								<div class="event-check-products-item">
									<div class="event-check-products-item-img">
										<img :src="require(`@/assets/img/main/${item.img}`)" :alt="item.title" />
									</div>
									<div class="event-check-products-item-title">
										<span class="item-title">{{ item.title }}</span>
										<span class="item-title boldtxt">{{ item.person }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="event-check-button-box">
				<div class="container">
					<button type="button" class="event-check-button" @click="clickAtndCheckEvent">찜하기 이벤트 참여</button>
				</div>
			</div>
			<div class="event-check-prect">
				<div class="container">
					<span class="event-check-prect-title">유의사항</span>
					<ul class="event-check-prect-list">
						<li class="event-check-prect-list-li" v-for="(item, idx) in prectList" :key="idx">
							<span class="prect-list-text">{{ item }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<event-terms-modal :visible="visibleModal" @closeModal="visibleModal = false" @agreeModal="prvcMktAgrePopup" />
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import EventTermsModal from '@/components/content/EventTermsModal';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';
import { ACT_SET_PRVC_MKT_AGRC } from '@/store/_act_consts';
import { isSuccess } from '@/assets/js/utils';
import { mapGetters } from 'vuex';
export default {
	name: 'EventDibs',
	components: { PageHeader, EventTermsModal },
	data: () => ({
		visibleModal: false,
		infoList: [
			{
				title: '참여기간',
				detail: '2024년 08월 28일(수) ~ 2024년 09월 28일(토)',
			},
			{
				title: '결과발표',
				detail: '2024년 10월 중',
			},
			{
				title: '경품발송',
				detail: '2024년 11월 중',
			},
		],
		prdList: [
		{
				img: 'item_image_2.jpg',
				title: '동행제품 선물박스(20만원 상당)',
				person: '(2명)',
			},
			{
				img: 'item_image_3.jpg',
				title: '온누리상품권 5만원',
				person: '(10명)',
			},
			{
				img: 'item_image_4.jpg',
				title: '커피 기프티콘',
				person: '(60명)',
			},
			{
				img: 'item_image_5.jpg',
				title: '아이스크림 쿠폰',
				person: '(100명)',
			},
		],
		prectList: ["동행축제 '찜하기 이벤트 참여' 버튼 클릭 후, 약관에 동의해야 이벤트에 응모됩니다."
			, '종료 시점까지 찜 상태의 상품이 있어야 이벤트 참여로 인정됩니다.'
			, '휴대폰 본인인증 후 이벤트에 참여할 수 있습니다.'
			, '당첨 후 경품의 제세 공과금(22%)는 본인이 부담해야 합니다.'
			, '당첨자는 이벤트 종료 후 1개월 이내 공지될 예정입니다.'
			, '당첨자의 잘못된 정보 입력으로 인해 경품 누락이 될 경우 재 발송되지 않습니다.'
			, '해당 이벤트에 14세 미만은 참여할 수 없습니다.'
			,'이벤트 일정 및 경품내역은 추후 주최측의 사정에 따라 변동 가능합니다.'
		],
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickAtndCheckEvent(e) {
			if (!this.chkLogin('로그인 후 이벤트 참여가 가능합니다.')) return false;
			//개인정보 동의 후 진행할 수 있도록 수정
			if (this.campn.prvcMktAgreYn === 'N') {
				this.visibleModal = true;
			} else {
				this.$router.push('/ko/products/category');
			}
		},
		chkLogin(title) {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: title,
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
		showAlert(msg) {
			this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: msg });
		},
		async prvcMktAgrePopup() {
			await this.$store.dispatch(`information/${ACT_SET_PRVC_MKT_AGRC}`, { campnId: this.campn.campnId }).then((res) => {
				if (isSuccess(res)) {
					this.campn.prvcMktAgreYn = 'Y';
					this.visibleModal = false;
					this.$router.push('/ko/products/category');
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
				}
			});
		},
	},
};
</script>
